import React from 'react';

const Notarypedia = () => {
  return (
    <div  className="notarypedia-content">
      <h1>This is a new page</h1>
      <p>Welcome to the new page!</p>
    </div>
  );
};

export default Notarypedia;
